/**
 * An object used to get page information from the server
 */
export class Page {
  // The number of elements in the page
  size = 0;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;
  // The current page number
  pageNumber = 0;
  offset = 0;
  limit = 10;
  search = '';
  role = '';
  roleIds = '';
  org = '';
  orgIds = '';
  leagueIds = '';
  eventIds = '';
  packageIds = '';
  type = '';
  teamIds = '';
  customListIds = '';
  agegroupIds = '';
  startDate = '';
  endDate = '';
  divisionId = '';
}
