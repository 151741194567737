import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LucideAngularModule } from 'lucide-angular';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { MDModalModule } from '../component/modals';

@Component({
  selector: 'app-image-cropper',
  standalone: true,
  imports: [
    ImageCropperModule,
    MDModalModule,
    CommonModule,
    LucideAngularModule
  ],
  templateUrl: './image-cropper.component.html',
  styleUrl: './image-cropper.component.scss'
})
export class ImageCropperComponent {
  imageUrl: string = '';
  containWithinAspectRatio = true;
  croppedImage: any = '';
  rotation = 0;
  scale = 1;
  backgroundColor = "#000000";
  cropperMinWidth = 300;
  cropperMinHeight = 300;
  @Input() aspectRatio = 16 / 9;
  @Input() fileName: string = '';
  @Output() cropperImageFile: any = '';
  @Input() imageChangedEvent: any = '';
  @Output() setCropperImgVal: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() handleCloseModel: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() handleSaveModal: EventEmitter<Event> = new EventEmitter<Event>();
  showSubmitButton: boolean = true;

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }


  imageCropped(event: ImageCroppedEvent) {
    if (event) {
      this.showSubmitButton = false;
    }
    if (event.blob) {
      const imgFile = new File([event.blob], "evtImage.jpeg", {
        type: "image/jpeg"
      });
      const customEvent = new CustomEvent('imageCropped', {
        detail: imgFile
      });
      this.setCropperImgVal.emit(customEvent);
    }

  }

  async blobUrlToFile(blobUrl: any) {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    blob.slice(0, blob.size, "image/png")
    return new File([blob], this.fileName, { type: 'image/png' });
  }

  loadImageFailed() {
    // show message
  }

  handleCloseModelClick() {
    this.handleCloseModel.emit();
  }

  handleSaveClick() {
    this.handleSaveModal.emit();
  }
}
