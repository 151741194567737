import { CommonModule, DatePipe, Location } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MnDropdownComponent } from '@app-shared/component/dropdown';
import { MDModalModule, MDModalsComponent } from '@app-shared/component/modals';
import { NGXPagination } from '@app-shared/component/pagination';
import { OrgclientService } from '@app/core/services/orgclient.service';
import { DeactivateClientComponent } from '@app/pages/deactivate-client/deactivate-client.component';
import { GenerateClientComponent } from '@app/pages/generate-client/generate-client.component';
import { ColumnMode, DatatableComponent, NgxDatatableModule } from '@siemens/ngx-datatable';
import { DisplayBlock } from './../../../core/helpers/helper.classes';
// import { FlatpickrInstance } from 'angularx-flatpickr';
// import { FlatpickrComponent } from 'angularx-flatpickr';

import { HttpClient } from '@angular/common/http';
import { EventTypeEnum } from '@app/core/helpers/api_constant';
import { EventType } from '@app/core/helpers/constants';
import { formatCurrency, formatStringAmountToDecimal, getFileName, parentChildOrganization, parseDateWithoutTimezone, removeDuplicates, showAlertMessage } from '@app/core/helpers/utils';
import { PipesModule } from '@app/core/pipes/pipes.module';
import { AdminCenterService } from '@app/core/services/admin-center.service';
import { AuthenticationService } from '@app/core/services/auth.service';
import { CommonDataService } from '@app/core/services/common-data-pass.service';
import { FileUploadService } from '@app/core/services/fileupload.service';
import { LeagueService } from '@app/core/services/league.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { PurchaseHistoryService } from '@app/core/services/purchase-history.service';
import { SendEmailService } from '@app/core/services/send-email.service';
import { SidebarService } from '@app/core/services/sidebar.service';
import { SKBucksService } from '@app/core/services/sk-bucks.service';
import { SubscribeSmsStoreService } from '@app/core/services/subscribe-sms-store.service';
import { TeamService } from '@app/core/services/team.service';
import { UserdataService } from '@app/core/services/userdata.service';
import { SwalService } from '@app/layouts/sidebar/swalService';
import { UpdatePackModalComponent } from '@app/pages/profile/view-purchase-history/package-history/update-pack-modal/update-pack-modal.component';
import { FlatpickrModule } from '@app/shared/component/flatpickr/flatpickr.module';
import { ModalService } from '@app/shared/component/modals/modal.service';
import { EPurchaseHistoryTableType } from '@app/shared/types/purchase-history.type';
import { EDocType } from '@app/shared/types/team.type';
import { NgSelectModule } from '@ng-select/ng-select';
import { LucideAngularModule } from 'lucide-angular';
import moment from 'moment';
import { NgxMaskDirective } from 'ngx-mask';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime } from 'rxjs';
import { DataTableModel } from '../../../core/helpers/helper.classes';
import { Page } from '../paging/model/page';

@Component({
  selector: 'app-datatable',
  standalone: true,
  imports: [
    NgxDatatableModule,
    NGXPagination,
    FormsModule,
    CommonModule,
    MDModalModule,
    RouterModule,
    MnDropdownComponent,
    GenerateClientComponent,
    DeactivateClientComponent,
    LucideAngularModule,
    PipesModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    FlatpickrModule,
    UpdatePackModalComponent
  ],
  templateUrl: './datatable.component.html',
  providers: [DatePipe],
  styleUrls: ['./datatable.component.scss'],
})

export class _DatatableComponent implements OnInit, OnChanges {


  @ViewChild(DatatableComponent) table!: DatatableComponent;
  @ViewChild('openModal') openModal!: MDModalsComponent;
  @ViewChild('viewDocuments') viewDocuments: MDModalsComponent | any;
  @ViewChild('dropdown') dropdown!: MnDropdownComponent;
  @ViewChild('flatpickrInput') flatpickrInput!: ElementRef;
  inputControl = new FormControl('');

  @Input() configDataTable: DataTableModel = new DataTableModel();
  // @Input() searching !: boolean;
  @Input() plushButton !: boolean;
  @Input() plusButtonText !: string;
  @Input() displayBlock!: DisplayBlock;
  @Input() isOptIn!: boolean;
  @Input() isShowDelete: boolean = true;
  @Input() disableAddBtn: boolean = false;
  @Input() isShowDuplicateEdit: boolean = false;
  @Input() isShowEdit: boolean = true;
  @Input() isShowViewPlanUser: boolean = false;
  @Input() tableType: string = '';
  @Input() flatpickrStartDateOptions: any = { mode: 'range', altFormat: 'm-d-Y', dateFormat: 'Y-m-d' };
  @Input() category: string = '';
  @Input() ids: any;
  @Input() selectedOrgIdsForCustomUser: any;
  @Input() tabType?: string | undefined;

  @Output() sendSMSToAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() goToViewUserPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() childClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() showNotes: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleAddBtn: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleDeleteRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() showSketchplayBucksReason: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleEditRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() addCommunity: EventEmitter<any> = new EventEmitter<any>();
  @Output() actionsButtonClick = new EventEmitter<{ type: string, row: any }>();
  @Output() checkedSelectedIds = new EventEmitter<any>()
  @Output() handleActivateDeactivate = new EventEmitter<any>()
  @Output() manageSKB = new EventEmitter<any>()
  @Output() exportDataFile = new EventEmitter<any>()
  @Output() totalDataOfTable = new EventEmitter<any>()

  isSendToAllClicked: boolean = false;
  @Output() sendToAllClicked = new EventEmitter<boolean>();

  updateDataTableValueForm!: FormGroup;
  PURCHASE_PACKAGE_HISTORY = EPurchaseHistoryTableType.PURCHASE_PACKAGE_HISTORY;
  PAYMENT_PLAN_HISTORY = EPurchaseHistoryTableType.PAYMENT_PLAN_HISTORY;


  page = new Page();
  rows: any;
  datatable: any;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number = 0;
  startIndex: number = 0;
  endIndex: any;
  columns!: any[];
  ColumnMode = ColumnMode;
  isSearching: boolean = false;
  isCustomerListFilterShow: boolean = false;
  isMyCommunitiesFilterShow: boolean = false;
  customEmptyMsg!: string;
  isShowEmptyMsg: boolean = false;
  isFilterOption: boolean = false;
  isAddBtn: boolean = false;
  // isInitialOrg: boolean = true;
  isViewDocument: boolean = false;
  isExportData: boolean = false;
  isComingFrom!: string;
  isComingFromId!: number;
  headerHeight: number = 0;
  getUrl: string = '';
  status = '';
  clientID = '';
  rolesOption: any = []
  teamNamesOption: any = []
  customListOption: any = []
  leagueNameOption: any = []
  singleEventList: any = []
  packageList: any = []
  eventType: any = [{ id: EventTypeEnum.CAMPS, name: 'Camps' }, { id: EventTypeEnum.CLINICS, name: 'Clinic' }, { id: EventTypeEnum.DAILY_TRAINING, name: 'Daily Training' }]
  selectedTeamIds: number[] = [];
  selectedCustomUserIds: number[] = [];
  selectedRoles: number[] = [];
  globalFilterString: string = "";
  orgClients: any[] = [];
  selectedOrgIds: any[] = [];
  isFilterOrg: boolean = false;
  isShowFilterByTeamName: boolean = false;
  isShowFilterByCustomList: boolean = false;
  isFilterByEvent: boolean = false;
  isFilterByType: boolean = false;
  isFilterByPackage: boolean = false;
  isShowSendToAllBtn: boolean = false;
  isFilterByLeague: boolean = false;
  isFilterParentOrg: boolean = false;
  isMultiselectOrg: boolean = false;
  isDeleteModal: boolean = false;
  selectedRow: any = null;
  loadingIndicator: boolean = true;
  type: string = '';
  selectedEventIds: number[] = [];
  selectedLeagueIds: number[] = [];
  selectedAgeGroupIds: number[] = [];
  selectedDivision: number[] = [];
  leagues: any[] = [];
  ageGroupData: any[] = [];
  divisionData: any[] = []
  rosterDocList: any[] = [];
  userId: any = 0
  teamDocList: any[] = [];
  id: any;
  typeOfDoc: any;
  leagueId: any;
  selected: any[] = [];
  selectedDoc: any;
  imgUploadLoader: boolean = false;
  docDetail: any;
  myId: any;
  rolesData = [];
  isEventPurchaseHistory: boolean = window.location.pathname === '/purchase-history' ? true : false;
  updatedPackCount: number | string = '';
  updatedRemainingAmount: number | string = '';
  selectedTableRow: any;
  showUpdateCountAmountModal: boolean = false;
  customMessages = {
    emptyMessage: ''
  };
  selectedRowsByPage: { [page: number]: Set<any> } = {};
  isSingleEventLoading: boolean = false;
  isPackageLoading: boolean = false;
  singleEventHasNextPage: boolean = true;
  singleEventFilterSkip: number = 0;
  packageHasNextPage: boolean = true;
  packageFilterSkip: number = 0;


  constructor(
    private sidebarService: SidebarService,
    private adminCenterService: AdminCenterService,
    private orgclientService: OrgclientService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private sendEmailService: SendEmailService,
    private swalService: SwalService,
    private leagueService: LeagueService,
    private ngxSpinnerService: NgxSpinnerService,
    private teamService: TeamService,
    private route: ActivatedRoute,
    private fileUploadService: FileUploadService,
    private http: HttpClient,
    private el: ElementRef,
    private renderer: Renderer2,
    private userdataService: UserdataService,
    private purchaseHistoryService: PurchaseHistoryService,
    private modalService: ModalService,
    private location: Location,
    private dataService: CommonDataService,
    private cdRef: ChangeDetectorRef,
    private subscribeSmsStoreService: SubscribeSmsStoreService,
    private SKBucksService: SKBucksService
  ) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.leagueId = parseInt(this.route.snapshot.queryParamMap.get('leagueId') ?? '0');
    let myData = JSON.parse(localStorage.getItem('userDetailsFromUserMe')!)
    this.myId = myData?.id
    this.inputControl.valueChanges
      .pipe(debounceTime(600))
      .subscribe(value => {
        this.updateFilter(value ?? '');
      });
  }

  ngOnInit(): void {
    //this.reloadData();
    this.dataTableBindind();
    this.getOrgClients();
    if (this.displayBlock?.type === 'registeredTeam') {
      this.getAgeGroups()
    }
    this.sidebarService.getAllRoles().subscribe(
      (data) => {
        this.rolesData = data?.data
      }
    );
    this.updateDataTableValueForm = new FormGroup({
      packRemainingCount: new FormControl(null, [
        Validators.required,
        Validators.minLength(0)
      ]),
    });

    if (this.isShowEmptyMsg) {
      this.customMessages.emptyMessage = this?.customEmptyMsg
    } else {
      this.customMessages.emptyMessage = 'No Data Found'
    }
    this.loadSelectedIds();
    this.cdRef.detectChanges(); // Manually trigger change detection

  }

  getAllRoles() {
    this.adminCenterService.getAllRolesForUserListPage().subscribe((data: any) => {
      this.rolesOption = data?.data;
    })
    if (this.displayBlock?.type === 'registeredTeam') {
      this.getAgeGroups()
    }
    this.getLeagueByOrg('');

  }
  handleChangeImage(event: any, docId: number, docDetail: any) {
    const file = event.target.files[0];
    this.selectedDoc = docId;
    this.imgUploadLoader = true;
    this.uploadImgFile(file, docId, docDetail);
  }

  uploadImgFile(file: File | null, docId: number, docDetail: any) {
    this.docDetail = docDetail;
    this.ngxSpinnerService.show();
    this.fileUploadService.uploadDocument(file).subscribe((response: any) => {
      if (response?.status && response?.data) {
        this.ngxSpinnerService.hide();
        let data = response?.data?.url;
        this.rosterDocList = this.rosterDocList?.map((item: any) => {
          if (item?.document?.id === docId) {
            return { ...item, url: data }
          } else {
            return item;
          }
        });
        this.imgUploadLoader = false;
        this.handleSubmitRosterDoc()
      } else {
        this.ngxSpinnerService.hide();
        showAlertMessage("Please select a JPG, or PNG file", "error");
        this.imgUploadLoader = false;
      }
    }, (error) => {
      this.ngxSpinnerService.hide();
      this.imgUploadLoader = false;
    }
    );
  }
  handleSubmitRosterDoc() {
    let docs: any = [];
    this.rosterDocList?.forEach((item: any) => {
      if (item?.url) {
        docs.push({ documentId: item?.document?.id, url: item?.url })
      }
    });

    let payload = {
      userId: this.docDetail?.user?.id,
      documents: docs,
    };

    this.ngxSpinnerService.show();
    this.teamService.addRosterDocument(payload).subscribe((response: any) => {
      this.ngxSpinnerService.hide();
      if (response?.status) {
        showAlertMessage(response?.message ?? "Success", "success", () => {
        });
      } else {
        showAlertMessage(response?.message ?? "Something went wrong!", "error");
      }
    }, () => {
      this.ngxSpinnerService.hide();
    });
  }

  goToAddPage(paymentId: any) {
    const queryParams: any = {
      productName: paymentId?.productName ?? "",
      firstName: paymentId?.user?.firstName ?? "",
      lastName: paymentId?.user?.lastName ?? "",
      createdAt: paymentId?.createdAt ?? "",
      paymentId: paymentId?.id ?? 0,
      purchaseDate: paymentId?.purchaseDate ?? "",
      eventName: paymentId?.eventName ?? "",
      paymentPlanName: paymentId?.paymentPlanName ?? "",
      planCost: paymentId?.planCost ?? paymentId?.originalAmount ?? 0,
      depositAmount: paymentId?.depositAmount ?? 0,
    };
    const search = location?.search;
    if (search) {
      if (search?.includes("profile=true")) {
        queryParams['profile'] = true;
      }
    }
    if (this.isComingFrom === 'purchase_history_packages') {
      this.router.navigate(['/packages-details'], {
        queryParams: {
          packageId: paymentId?.id,
          ...queryParams
        }
      });
    } else {
      this.router.navigate(['/payment-plans-transactions'], {
        queryParams: {
          ...queryParams
        }
      });
      const url = this.location.path();
      history.pushState({ planTransactionsScreen: true }, '', url);
    }
    this.dataService.setData(this.ids);
  }

  goToViewPlanForUsers(paymentPlanId: any, row?: any) {
    this.router.navigate(['/view-payment-plans'], { queryParams: { paymentPlanId: paymentPlanId, planName: row?.name } });
  }

  goToViewCustomList(row: any) {
    this.router.navigate(['/view-custom-lists-users'], { queryParams: { customUserIds: row?.id, customUserName: row?.name } });
  }

  duplicateAndEditSendEmail(row: any) {
    this.router.navigate(['/send-email'], { queryParams: { campaignId: row?.id, isDuplicate: true } });
    localStorage.setItem('selectedTab', 'all-users');
  }

  goToDeliveryReportPage(row: any) {
    this.router.navigate(['/view-delivery-report'], { queryParams: { campaignId: row?.id, type: this.isComingFrom } });
  }

  goToViewSendEmailUsers(row: any) {
    const payload = {
      row: row,
      step: 5
    }
    // this.router.navigate(['/view-recipient-users'], { queryParams: { userIds: row?.id, name: row?.name } });
    this.goToViewUserPage.emit(payload);
  }

  getOrgClients() {
    if (this.isComingFrom === 'sk-bucks-admin-list') {
      this.SKBucksService.getSKBucksOrganization().subscribe((response: any) => {
        if (response?.status) {
          this.orgClients = response?.data?.docs ?? [];
        }
      })
    } else {
      this.authenticationService.userMe().subscribe((response: any) => {
        if (response?.status) {
          this.userId = response?.data?.id;
          this.userdataService.setUserData(response?.data);
          const allOrgs = response?.data?.organizations;

          const filterOrgClients = allOrgs?.filter((item: any) => item.roles.some((role: any) => role?.type === "admin"));

          this.selectedOrgIdsForCustomUser = Array.isArray(this.selectedOrgIdsForCustomUser) ? this.selectedOrgIdsForCustomUser.join(
            ',') : this.selectedOrgIdsForCustomUser

          if (this.isComingFrom === 'create-sms') {
            const storedOrg = this.subscribeSmsStoreService.getSelectedOrg();
            if (storedOrg) {
              this.selectedOrgIds = [storedOrg];
            }
          }

          const selectedOrgIdssss = this.selectedOrgIdsForCustomUser
            ? this.selectedOrgIdsForCustomUser?.includes(',')
              ? this.selectedOrgIdsForCustomUser?.split(',').map((id: any) => {
                const trimmedId = id.trim();
                const numId = Number(trimmedId);
                return isNaN(numId) ? null : numId;
              }).filter((id: any) => id !== null)
              : [Number(this.selectedOrgIdsForCustomUser)]
            : [];

          const filterOrgClientsForCustomUser = allOrgs?.filter((item: any) =>
            selectedOrgIdssss.includes(item?.id)
          );

          if (this.isComingFrom === 'CUSTOMER_LIST_STEP_2') {
            this.orgClients = filterOrgClientsForCustomUser;
            if (this.selectedOrgIdsForCustomUser) {
              if (this.selectedOrgIdsForCustomUser.includes(',')) {
                this.selectedOrgIds = this.selectedOrgIdsForCustomUser?.split(',')
                  .map((id: any) => Number(id.trim()));
              } else {
                this.selectedOrgIds = [Number(this.selectedOrgIdsForCustomUser)];
              }
            }
          } else if (this.isFilterParentOrg) {
            this.orgClients = filterOrgClients;
          } else {
            const parentChildOrgs = parentChildOrganization(allOrgs)
            const filterOrgClients = parentChildOrgs?.filter((item: any) => item.roles.some((role: any) => role?.type === "admin"));
            this.orgClients = filterOrgClients;
          }
        }
      })
    }
  }

  getTeamName() {
    this.sendEmailService.getTeamNames(this.selectedOrgIds).subscribe((response: any) => {
      if (response?.status) {
        this.teamNamesOption = response?.data?.docs;
      }
    })
  }

  getCustomUserList() {
    this.sendEmailService.getCustomUserList(this.selectedOrgIds).subscribe((response: any) => {
      if (response?.status) {
        this.customListOption = response?.data?.docs;
      }
    })
  }

  getLeagueName() {
    this.sendEmailService.getLeagueName(this.selectedOrgIds).subscribe((response: any) => {
      if (response?.status) {
        this.leagueNameOption = response?.data?.docs;
      }
    })
  }
  getPackageLazyLoad() {
    if (this.packageHasNextPage) {
      this.packageFilterSkip += 10;
      this.getPackage();
    }
  }
  getSingleEventLazyLoad() {
    if (this.singleEventHasNextPage) {
      this.singleEventFilterSkip += 10;
      this.getSingleEvent();
    }
  }
  getSingleEvent() {
    this.isSingleEventLoading = true;
    let payload = {
      skip: this.singleEventFilterSkip,
      limit: 10,
      orgIdsArrStr: this.selectedOrgIds ? this.selectedOrgIds?.join(',') : '',
    }
    this.purchaseHistoryService.getEvent(payload).subscribe(
      (response) => {
        this.isSingleEventLoading = false;
        if (response?.status) {
          const dataDocs = response?.data?.docs;
          this.singleEventHasNextPage = response?.data?.hasNextPage ?? false;
          if (this.selectedOrgIds && this.selectedOrgIds?.length) {
            this.singleEventList = removeDuplicates([...this.singleEventList, ...dataDocs], 'id')?.filter((event: any) => this.selectedOrgIds?.includes(event?.organizationId));
          } else {
            this.singleEventList = removeDuplicates([...this.singleEventList, ...dataDocs], 'id');
          }
        }
      }
    );
  }

  getPackage() {
    this.isPackageLoading = true;
    let payload = {
      skip: this.packageFilterSkip,
      limit: 10,
      orgIdsArrStr: this.selectedOrgIds ? this.selectedOrgIds?.join(',') : '',
    }
    this.purchaseHistoryService.getPackage(payload).subscribe(
      (response) => {
        if (response?.status) {
          this.isPackageLoading = false;
          const dataDocs = response?.data?.docs;
          this.packageHasNextPage = response?.data?.hasNextPage ?? false;
          if (this.selectedOrgIds && this.selectedOrgIds?.length) {
            this.packageList = removeDuplicates([...this.packageList, ...dataDocs], 'id');
            this.packageList = this.packageList.filter((pack: any) =>
              pack?.organizationIds.some((orgId: number) => this.selectedOrgIds?.includes(orgId))
            );
          } else {
            this.packageList = removeDuplicates([...this.packageList, ...dataDocs], 'id');
          }
        }
      }
    );
  }
  renderDocStatusLabelClass(status: string) {
    if (status === EDocType.NOT_STARTED || status === EDocType.CANCELED) {
      return 'text-[#EF4444]'
    } else if (status === EDocType.PENDING || status === EDocType.PENDING_1) {
      return 'text-[#FFAB00]'
    } else if (status === EDocType.FAIL) {
      return 'text-[#EF4444]'
    } {
      if (!status) {
        return 'text-[#EF4444]'
      } else {
        if (this.tableType === 'PAYMENT_PLAN_HISTORY' && status === EDocType.ACTIVE) {
          return 'text-[#FFAB00]'
        } else {
          return 'text-[#249782]'
        }
      }
    }
  }

  renderDocStatusContainerClass(status: string) {
    if (status === EDocType.NOT_STARTED || status === EDocType.CANCELED) {
      return 'bg-[#EF444433]'
    } else if (status === EDocType.PENDING || status === EDocType.PENDING_1) {
      return 'bg-[#FFAB0033]'
    } else if (status === EDocType.FAIL) {
      return 'bg-[#EF444433]'
    } {
      if (!status) {
        return 'bg-[#EF444433]'
      } else {
        if (this.tableType === 'PAYMENT_PLAN_HISTORY' && status === EDocType.ACTIVE) {
          return 'bg-[#FFAB0033]'
        } else {
          return 'bg-[#4CAA9933]'
        }
      }
    }
  }

  getAgeGroups() {
    this.leagueService.getLeagueById(this.displayBlock?.key).subscribe((response: any) => {
      if (response?.status) {
        this.ageGroupData = response?.data?.ageGroups;
        this.divisionData = response?.data?.divisions;
      }
    })
  }

  getLeagueByOrg(ids: any) {
    const payload = {
      orgIds: ids,
      skip: 0,
      limit: 1000,
      isActive: true
    }
    this.ngxSpinnerService.show();
    this.leagueService.getLeagueList(payload).subscribe((response: any) => {
      this.ngxSpinnerService.hide();
      if (response?.status) {
        this.leagues = response?.data?.docs ?? [];
      }
    }, () => {
      this.ngxSpinnerService.hide();
    });
  }

  plusButtonClick(text: string) {
    // if (text === "Add event schedule") {
    //   this.router.navigate(['/add-event-schedule']
    //     // , { queryParams: { eventSchedule: 'eventSchedule' } }
    //   )
    // }
    this.handleAddBtn.emit()
  }

  onRoleSelectionChange(event: any) {
    // this.globalFilterString = this.selectedRoles.join(',');
    if (event) {
      this.page.roleIds = event.map((team: any) => team?.id).join(',');
    }
    this.startIndex = 0;
    this.reloadData();
  }

  onTeamNameChange(event: any) {
    if (event && event.length > 0) {
      this.page.teamIds = event.map((team: any) => team?.id).join(',');
    } else {
      this.page.teamIds = '';
    }
    this.startIndex = 0;
    this.reloadData();
  }

  onCustomUserChange(event: any) {
    if (event && event.length > 0) {
      this.page.customListIds = event.map((team: any) => team?.id).join(',');
    } else {
      this.page.customListIds = '';
    }
    this.startIndex = 0;
    this.reloadData();
  }

  onLeagueNameChange(event: any) {
    if (event && event.length > 0) {
      this.page.leagueIds = event.map((team: any) => team?.id).join(',');
    } else {
      this.page.leagueIds = '';
    }
    this.startIndex = 0;
    this.reloadData();
  }

  onEventNameChange(event: any) {
    if (event && event.length > 0) {
      this.page.eventIds = event.map((team: any) => team?.id).join(',');
    } else {
      this.page.eventIds = '';
    }
    this.startIndex = 0;
    this.reloadData();
  }
  onPackageChange(event: any) {
    if (event && event.length > 0) {
      this.page.packageIds = event.map((team: any) => team?.id).join(',');
    } else {
      this.page.packageIds = '';
    }
    this.startIndex = 0;
    this.reloadData();
  }
  onTypeChange(event: any) {
    if (event && event.length > 0) {
      this.page.type = event.map((team: any) => team?.id).join(',');
    } else {
      this.page.type = '';
    }
    this.startIndex = 0;
    this.reloadData();
  }
  updateFilter(value: string) {
    const val = value.toLowerCase();
    this.page.search = val;
    this.startIndex = 0;
    this.reloadData();
  }

  handle_ORG_Click(event: any, row: any) {
    this.childClick.emit(row); // Wrap the arguments in an object
    //this.childClick.emit(event, row);
  }

  reloadData() {
    this.dataTableBindind();
    this.setPage({ offset: this.startIndex });
  }

  setPage(pageInfo: any) {
    this.page.pageNumber = this.currentPage - 1;
    this.page.offset = pageInfo.offset;
    this.loadingIndicator = true;
    // if (this.isComingFrom === "CUSTOMER_LIST_STEP_2" && this.isInitialOrg) {
    //   this.page.orgIds = this.selectedOrgIdsForCustomUser;
    // }
    this.rows = [];
    if (this.displayBlock && typeof pageInfo?.isOptIn !== 'undefined' && typeof pageInfo?.isOptIn !== null) {
      this.displayBlock['queryparam'] = `?isOptIn=${pageInfo?.isOptIn}&`;
      if (pageInfo?.orgId) {
        this.displayBlock['queryparam'] += `orgIds=${pageInfo?.orgId ?? ''}&`
      }
    }
    //this.page.limit = pageInfo.limit;
    if (this.displayBlock !== undefined) {
      this.orgclientService.getResults(this.page, this.displayBlock).subscribe(pagedData => {
        this.page = pagedData.page;
        // this.rows = [];
        if (this.tableType === this.PAYMENT_PLAN_HISTORY) {
          this.rows = pagedData.data[0]?.map((item: any) => {
            return {
              ...item,
              remainingAmountVal: item['remainingAmount'],
              remainingAmount: formatStringAmountToDecimal(item['remainingAmount']),
              remainingAmountLabel: `$${formatStringAmountToDecimal(item['remainingAmount'])}`
            }
          });
        } else {
          this.rows = pagedData.data[0];
        }
        this.loadSelectedIds();
        this.loadingIndicator = false;
        this.totalItems = this.page.totalElements;
        this.totalDataOfTable.emit(this.totalItems);
        this.currentPage = this.page.pageNumber;
        this.itemsPerPage = this.page.limit;

        const footerElement = this.el.nativeElement.querySelector('.datatable-footer');
        if (footerElement && this.rows?.length < 4) {
          this.renderer.setStyle(footerElement, 'z-index', '0');
        }

        else {
          this.renderer.setStyle(footerElement, 'z-index', '25');
        }

      },
        (error: any) => {
          this.loadingIndicator = false;
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  dataTableBindind() {
    this.columns = this.configDataTable?.displayBlock?.rows ?? [];
    this.displayBlock = this.configDataTable?.displayBlock;
    this.itemsPerPage = this.displayBlock?.perPage ?? 5;
    this.page.size = this.displayBlock?.perPage ?? 5;
    this.page.limit = this.displayBlock?.perPage ?? 5;
    this.isSearching = this.displayBlock?.isSearching ?? false;
    this.isCustomerListFilterShow = this.displayBlock?.isCustomerListFilterShow ?? false;
    this.isMyCommunitiesFilterShow = this.displayBlock?.isMyCommunitiesFilterShow ?? false;
    this.isComingFrom = this.displayBlock?.isComingFrom ?? "";
    this.isComingFromId = this.displayBlock?.isComingFromId ?? "";
    this.customEmptyMsg = this.displayBlock?.customEmptyMsg ?? "";
    this.isShowEmptyMsg = this.displayBlock?.isShowEmptyMsg ?? false;
    this.isFilterOption = this.displayBlock?.isFilterOption ?? false;
    this.isAddBtn = this.displayBlock?.isAddBtn ?? false;
    this.isViewDocument = this.displayBlock?.isViewDocument ?? false;
    this.isExportData = this.displayBlock?.isExportData ?? false;
    this.headerHeight = this.displayBlock?.headerHeight ?? 0;
    this.getUrl = this.displayBlock?.getUrl ?? '';
    this.isFilterOrg = this.displayBlock?.isFilterOrg ?? false;
    this.isShowFilterByTeamName = this.displayBlock?.isShowFilterByTeamName ?? false;
    this.isShowFilterByCustomList = this.displayBlock?.isShowFilterByCustomList ?? false;
    this.isFilterByEvent = this.displayBlock?.isFilterByEvent ?? false;
    this.isFilterByType = this.displayBlock?.isFilterByType ?? false;
    this.isFilterByPackage = this.displayBlock?.isFilterByPackage ?? false;
    this.isShowSendToAllBtn = this.displayBlock?.isShowSendToAllBtn ?? false;
    this.isFilterByLeague = this.displayBlock?.isFilterByLeague ?? false;
    this.isFilterParentOrg = this.displayBlock?.isFilterParentOrg ?? false;
    this.isMultiselectOrg = this.displayBlock?.isMultiselectOrg ?? false;
    this.isDeleteModal = this.displayBlock?.isDeleteModal ?? false;
    this.type = this.displayBlock?.type ?? '';
    this.id = this.displayBlock?.id ?? '';
    if (this.isFilterOption) {
      this.getAllRoles()
    }
    const savedTabId = localStorage.getItem('selectedTab');
    if (savedTabId === "teams") {
      this.getTeamName();
    } else if (savedTabId === "leagues-tournaments") {
      this.getLeagueName();
    } else if (savedTabId === "custom-users") {
      this.getCustomUserList();
    } else if (savedTabId === "events") {
      this.getSingleEvent();
    } else if (savedTabId === "purchases") {
      this.getPackage();
    }

    if (this.type === 'teamSubmission') {
      this.getLeagueByOrg('');
    }
    this.cdRef.detectChanges()

  }

  onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.updatePagedOrders();
  }

  getEndIndex() {
    return Math.min(this.startIndex + this.itemsPerPage, this.totalItems);
  }

  updatePagedOrders(): void {
    this.startIndex = (this.currentPage - 1) * this.itemsPerPage;
    this.endIndex = this.startIndex + this.itemsPerPage;
    this.dataTableBindind();
    this.setPage({ offset: this.startIndex, limit: this.endIndex });
    // this.datatable = dataTable.slice(this.startIndex, this.endIndex);
  }

  // onTreeAction(event: any) {
  //   const index = event.rowIndex;
  //   const row = event.row;
  //   if (row.treeStatus === 'collapsed') {
  //     row.treeStatus = 'expanded';
  //   } else {
  //     row.treeStatus = 'collapsed';
  //   }
  //   this.rows = [...this.rows];
  // }

  handleChangeOrganizations(event: any) {
    // this.isInitialOrg = false;
    if (this.type === 'teamSubmission') {
      if (event.length != 0) {
        //this.leagues = [];
        this.selectedLeagueIds = [];
        this.page.leagueIds = '';
        if (typeof this.selectedOrgIds === 'number' || typeof this.selectedOrgIds === 'string') {
          this.page.org = this.selectedOrgIds;
          this.getLeagueByOrg(this.selectedOrgIds);
        } else {
          this.page.orgIds = this.selectedOrgIds.join(',');
          this.getLeagueByOrg(this.selectedOrgIds.join(','));
        }
        // this.selectedLeagueIds = [];
      } else {
        // this.leagues = [];
        this.selectedLeagueIds = [];
        this.page.leagueIds = '';
      }
    } else {
      if (event) {
        if (typeof this.selectedOrgIds === 'number' || typeof this.selectedOrgIds === 'string') {
          this.page.org = this.selectedOrgIds;
        } else {
          this.page.orgIds = this.selectedOrgIds.join(',');
          if (this.type === 'SEND_EMAIL') {
            if (this.tabType === 'events') {
              this.singleEventFilterSkip = 0;
              this.getSingleEvent();
            }
            if (this.tabType === 'purchases') {
              this.packageFilterSkip = 0;
              this.getPackage();
            }
          }
        }
      } else {
        this.page.org = '';
      }
    }
    this.startIndex = 0;
    this.reloadData();

  }
  handleToggleChange(event: Event, row: any, message: string): void {
    const checkbox = event.target as HTMLInputElement;
    const confirmationMessage = checkbox.checked
      ? `Are you sure you want to activate this ${message}?`
      : `Are you sure you want to deactivate this ${message}?`;
    const buttonText = checkbox.checked
      ? 'Yes Activate'
      : 'Yes Deactivate';
    this.swalService.conformationPopup(confirmationMessage, 'none', () => {
      row.isActive = checkbox.checked;
      this.handleActivateDeactivate.emit(row)
    }, () => { }, '', buttonText, 'Cancel',);
  }
  handleChangeLeague(event: any) {
    if (event) {
      if (typeof this.selectedLeagueIds === 'number' || typeof this.selectedLeagueIds === 'string') {
        this.page.leagueIds = this.selectedLeagueIds;
      } else {
        this.page.leagueIds = this.selectedLeagueIds.join(',');
      }
    } else {
      this.page.leagueIds = '';
    }

    this.startIndex = 0;
    this.reloadData();
  }

  handleChangeAgeGroup(event: any) {
    if (event) {
      if (typeof this.selectedAgeGroupIds === 'number' || typeof this.selectedAgeGroupIds === 'string') {
        this.page.agegroupIds = this.selectedAgeGroupIds;
      } else {
        this.page.agegroupIds = this.selectedAgeGroupIds.join(',');
      }
    } else {
      this.page.agegroupIds = '';
    }

    this.startIndex = 0;
    this.reloadData();
  }

  handleChangeDivision(event: any) {
    if (event) {
      if (typeof this.selectedDivision === 'number' || typeof this.selectedDivision === 'string') {
        this.page.divisionId = this.selectedDivision;
      } else {
        this.page.divisionId = this.selectedDivision.join(',');
      }
    } else {
      this.page.divisionId = '';
    }

    this.startIndex = 0;
    this.reloadData();
  }

  editRow(row: any) {
    this.handleEditRow.emit(row);
    // this.startIndex = 0;
    // this.reloadData();
    if (this.isComingFrom === "view-custom-lists") {
      this.router.navigate(['/manage-email/create-custom-list'], { queryParams: { customUserId: row?.id, isDuplicate: true } });
    }
  }

  openNotesModal(row: any) {
    this.showNotes.emit(row)
  }

  deleteRow(row: any) {
    this.swalService.conformationPopup('Are you sure you want to delete?', 'none', () => {
      this.handleDeleteRow.emit(row);
      this.startIndex = 0;
      this.reloadData();
    }, () => { }, '', 'Yes, Delete', 'Cancel',);
  }

  activateDeactivateCommunity(row: any) {
    const message = row?.isActive ? 'Deactivate' : 'Activate'
    this.swalService.conformationPopup(`Are you sure you want to ${message}?`, 'none', () => {
      this.handleDeleteRow.emit(row);
      this.startIndex = 0;
      this.reloadData();
    }, () => { }, '', `Yes, ${message}`, 'Cancel',);
  }

  deleteRowForModal(row: any) {
    this.handleDeleteRow.emit(row);
  }

  trainingDateFormate(date: any) {
    return parseDateWithoutTimezone(date, 'MM/DD/YYYY');
  }

  installmentDateInMonthFormat(currentInstallmentDate: any) {
    const day = currentInstallmentDate;
    let suffix;
    if (day % 10 === 1 && day !== 11) {
      suffix = 'st';
    } else if (day % 10 === 2 && day !== 12) {
      suffix = 'nd';
    } else if (day % 10 === 3 && day !== 13) {
      suffix = 'rd';
    } else {
      suffix = 'th';
    }
    return `${day}${suffix} of month`;
  }


  viewTeamRoster(routingUrl: string, row: any) {
    this.router.navigate([routingUrl]
      , { queryParams: { teamId: row?.team?.id, teamRequestId: row?.id } }
    )
  }

  viewLeagueTeamRoster(routingUrl: string, row: any) {
    this.router.navigate([routingUrl]
      , { queryParams: { teamId: row?.id, leagueId: this.leagueId } }
    )
  }
  viewPlanDetails(routingUrl: string, row: any) {
    this.router.navigate([routingUrl]
      , { queryParams: { paymentPlanId: row?.paymentPlanId, userId: row?.user?.id } }
    )
  }

  viewDocument(row: any, type: any) {
    if (type == 'teamDoc') {
      this.typeOfDoc = type;
      this.getTeamDocumentList(this.id)
    } else {
      this.typeOfDoc = type;
      this.getRosterDocumentList(row.athlete.id)
    }
  }
  getRosterDocumentList(id: any) {
    this.rosterDocList = [];
    this.ngxSpinnerService.show();
    this.teamService.getRosterDocuments(id).subscribe((response: any) => {
      this.ngxSpinnerService.hide();
      if (response?.status) {
        this.rosterDocList = response?.data;
      }
    }, () => {
      this.ngxSpinnerService.hide();
    });
    this.viewDocuments.open();
  }
  getTeamDocumentList(teamId: any) {
    this.rosterDocList = [];
    this.ngxSpinnerService.show();
    this.teamService.getTeamDetails(teamId).subscribe((response: any) => {
      this.ngxSpinnerService.hide();
      if (response?.status) {
        const coachWaiver = response?.data?.coachWaivers;
        const teamDocuments = response?.data?.teamDocuments;
        this.rosterDocList = [...teamDocuments];
      }
    }, () => {
      this.ngxSpinnerService.hide();
    });
    this.viewDocuments.open();
  }
  closeviewDocuments() {
    this.viewDocuments.close();
  }

  bindArrayData(arrayData: any[]) {
    const filterData = arrayData?.map(team => team?.ageGroup?.name).join(', ');
    return filterData ?? '-';
  }

  onButtonActionClick(type: string, row: any) {
    this.actionsButtonClick.emit({ type, row });
  }

  renderDocFileName(url: string) {
    if (url) {
      return getFileName(url)
    } else {
      return ''
    }
  }

  showTeamDocFile(url: string) {
    window.open(url, '_blank');
  }

  exportData() {
    if (this.isComingFrom === 'delivery-report') {
      this.exportDataFile.emit()
      return;
    }
    if (this.isComingFrom === 'custom-users') {
      this.ngxSpinnerService.show();
      this.fileUploadService.exportCustomList(this.isComingFromId).subscribe((response: any) => {
        if (response?.status) {
          this.ngxSpinnerService.hide();
          const url = response?.data;
          this.downloadFile(url);
        } else {
          showAlertMessage(response?.message ?? "Something went wrong!", "error");
          this.ngxSpinnerService.hide();
        }
      }, () => {
        this.ngxSpinnerService.hide();
      });
      return;
    }
    if (this.selectedOrgIds.length == 0 && this.isComingFrom !== 'delivery-report') {
      showAlertMessage("You must select an organization before exporting data.", "error");
      return;
    }
    else if (this.selectedOrgIds.length > 1 && this.isComingFrom !== 'delivery-report') {
      showAlertMessage("Only one organization can be exported at a time. Please remove duplicate orgs.", "error");
      return;
    }
    this.ngxSpinnerService.show();
    if (this.isComingFrom === 'sk-bucks-admin-list') {
      this.fileUploadService.downloadSKBucksHistoryByOrgId(this.selectedOrgIds).subscribe((response: any) => {
        this.ngxSpinnerService.hide();
        if (response?.status) {
          const url = response?.data;
          this.downloadFile(url);
        } else {
          showAlertMessage(response?.message ?? "Something went wrong!", "error");
        }
      }, () => {
        this.ngxSpinnerService.hide();
      });
    } else {
      this.fileUploadService.downloadUserByOrgId(this.selectedOrgIds).subscribe((response: any) => {
        this.ngxSpinnerService.hide();
        if (response?.status) {
          const url = response?.data;
          this.downloadFile(url);
        } else {
          showAlertMessage(response?.message ?? "Something went wrong!", "error");
        }
      }, () => {
        this.ngxSpinnerService.hide();
      });
    }
  }
  downloadFile(url: string) {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = 'teamRoster.xlsx';
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  createPushNotification(isSendAll: boolean) {
    if (this.type === 'SMS') {
      let sms = {
        isSendAll
      }
      this.sendSMSToAll.emit(sms)
    } else {
      this.router.navigate(['/send-push-notification'],
        {
          queryParams:
          {
            isSendAll,
            selectedUserIds: isSendAll ? [] : this.selected,
            selectedOrgIds: isSendAll && !this.selectedOrgIds?.length ? [] : this.selectedOrgIds
          }
        }
      );
    }
  }

  onCheckboxChange(event: any, row: any) {
    this.selected = this.selected || [];
    if (event.target.checked) {
      this.selected?.push(row?.id);
      this.selectedRowsByPage[this.currentPage]?.add(row.id)
    } else {
      this.selected = this.selected?.filter(item => item !== row?.id);
      this.selectedRowsByPage[this.currentPage]?.delete(row.id)
    }
    this.checkedSelectedIds.emit(this.selected);
    const setInLocalStorage = ['all-users', 'leagues-tournaments', 'teams', "CUSTOMER_LIST", 'custom-users', 'create-sms', 'CUSTOMER_LIST_STEP_2', 'events', 'purchases'];
    if ((setInLocalStorage || []).includes(this.isComingFrom)) {
      localStorage.setItem('selectedIds', JSON.stringify(this.selected));
    }
  }

  loadSelectedIds() {
    let selectedData = this.localStorageService.getSaveData('selectedIds')
    if (selectedData && selectedData?.length && this.isComingFrom === 'leagues-tournaments' || this.isComingFrom === 'teams' || this.isComingFrom === 'custom-users' || this.isComingFrom === 'events' || this.isComingFrom === 'purchases') {
      if (this.rows?.length) {
        const sameIdFilterData = selectedData.filter((rowId: any) => {
          const row = this.rows.find((item: any) => item.id === rowId);
          return row?.userCount !== 0
        });
        this.localStorageService.saveData('selectedIds', sameIdFilterData)
        this.checkedSelectedIds.emit(sameIdFilterData);
        selectedData = [...sameIdFilterData];
      }
    }
    this.selected = selectedData ? selectedData : [];
    this.selectedRowsByPage = {};
    if (this.selected.length && this.rows?.length) {
      this.selected = Array.from(new Set(this.selected));
      this.selected.forEach((id: any) => {
        if (!this.selectedRowsByPage[this.currentPage]) {
          this.selectedRowsByPage[this.currentPage] = new Set();
        }
        this.selectedRowsByPage[this.currentPage].add(id);
      });
    }
  }

  getPageIndexForId(id: any): number {
    // Adjust this logic based on how you calculate page indices
    const rowIndex = this.rows.findIndex((row: any) => row.id === id);
    return Math.floor(rowIndex / this.page.limit);
  }

  isSelected(row: any) {
    // backup code
    // this.checkedSelectedIds.emit(this.selected.includes(row?.id))
    if (this.type === "CUSTOMER_LIST") {
      this.selected?.push(this.myId)
    }
    return this.selected?.includes(row?.id);
  }

  optInOptOutStatus(status: any, orgId?: number) {
    this.setPage({ offset: this.startIndex, isOptIn: status, orgId: orgId });
    this.dataTableBindind();
  }

  selectAllRows(event?: any, type?: string) {
    if (event?.target?.checked && type === 'checkbox') {
      if (this.isComingFrom === 'create-sms') {
        // Add all row IDs directly when coming from 'create-sms'
        const allIds = this.rows.map((row: any) => row.id);
        this.selected = Array.from(new Set(allIds)); // Ensure uniqueness
        if (!this.selectedRowsByPage[this.currentPage]) {
          this.selectedRowsByPage[this.currentPage] = new Set();
        }
        this.rows.forEach((row: any) => this.selectedRowsByPage[this.currentPage]?.add(row.id));
        this.checkedSelectedIds.emit(this.selected); // Emit all selected IDs
        return; // Exit the function as no further processing is needed
      }
      if (!this.selectedRowsByPage[this.currentPage]) {
        this.selectedRowsByPage[this.currentPage] = new Set();
      }
      this.rows.forEach((row: any) => this.selectedRowsByPage[this.currentPage]?.add(row.id));
      const currentPageSelectedIds = Array.from(this.selectedRowsByPage[this.currentPage])
      const sameIdFilterData = currentPageSelectedIds.filter((rowId: any) => {
        const row = this.rows.find((item: any) => item.id === rowId);
        return rowId !== this.myId && row?.userCount !== 0
      });
      if (this.selected) {
        this.selected = [...this.selected, ...sameIdFilterData];
      } else {
        this.selected = sameIdFilterData;
      }
      this.selected = Array.from(new Set(this.selected));
      this.checkedSelectedIds.emit(this.selected)
    } else if (type === 'PUSH_NOTIFICATION' || type === 'SEND_EMAIL' || type === 'create-sms') {
      if (type !== 'SEND_EMAIL' && this.type !== 'SMS') {
        this.rows.forEach((row: any) => {
          this.selected.push(row?.id);
        });
      }
      if (type === 'PUSH_NOTIFICATION') {
        this.createPushNotification(true)
      } else {
        if (this.selected && this.selected.length > 0) {
          this.isSendToAllClicked = false;
        } else {
          this.isSendToAllClicked = true;
        }
        this.sendToAllClicked.emit(this.isSendToAllClicked);
        return;
      }
    } else {
      if (this.selectedRowsByPage[this.currentPage]) {
        this.rows.forEach((row: any) => {
          this.selectedRowsByPage[this.currentPage].delete(row.id)
          this.selected = this.selected?.filter(x => x != row.id)
        });
      } else {
        this.selected = [];
      }
      this.checkedSelectedIds.emit(this.selected)
    }
    const setInLocalStorage = ['all-users', 'leagues-tournaments', 'teams', "CUSTOMER_LIST", 'custom-users', 'CUSTOMER_LIST_STEP_2', 'manage-user', 'create-sms', 'PUSH_NOTIFICATION', 'events', 'purchases'];
    if (setInLocalStorage.includes(this.isComingFrom)) {
      localStorage.setItem('selectedIds', JSON.stringify(this.selected));
    }
  }

  // FOR BACKUP
  // selectAllRows(event?: any, type?: string) {
  //   const currentPage = this.page.offset;
  //   if (event?.target?.checked && type === 'checkbox') {
  //     if (!this.selectedRowsByPage[currentPage]) {
  //       this.selectedRowsByPage[currentPage] = new Set();
  //     }
  //     this.rows.forEach((row: any) => this.selectedRowsByPage[currentPage].add(row.id));
  //     const currentPageSelectedIds = Array.from(this.selectedRowsByPage[currentPage])
  //     const filterData = this.rows?.filter((x: any) => x.id)
  //     const sameIdFilterData = filterData.filter((row: any) => row.id !== this.myId);
  //     const ids = sameIdFilterData?.map((x: any) => x.id)
  //     this.selected = [...ids];
  //     this.checkedSelectedIds.emit(this.selected)
  //   } else if (type === 'PUSH_NOTIFICATION') {
  //     this.rows.forEach((row: any) => {
  //       this.selected.push(row?.id);
  //     });
  //     this.createPushNotification(true)
  //   } else {
  //     this.selected = [];
  //     if (this.selectedRowsByPage[currentPage]) {
  //       this.rows.forEach((row: any) =>
  //         this.selectedRowsByPage[currentPage].delete(row.id)
  //       );
  //     }
  //     // Update selected data
  //     if (this.selectedRowsByPage[currentPage]) {
  //       const currentPageSelectedIds = Array.from(this.selectedRowsByPage[currentPage]);
  //       this.selected = [...this.selected, ...currentPageSelectedIds];
  //     }
  //     this.checkedSelectedIds.emit(this.selected)
  //   }
  // }

  ngOnDestroy() {
    if (this.viewDocuments?.modalService?.modals?.viewDocuments) {
      this.viewDocuments?.close();
    }
  }
  addToCommunity() {
    if (this.selected.length > 0) {
      this.addCommunity.emit(true)
    } else {
      showAlertMessage("Please select at least one user", "error");
    }
  }
  allRowsSelected(): boolean {
    if (!this.selectedRowsByPage[this.currentPage]) {
      return false;
    }
    return this.rows.every((row: any) => this.selectedRowsByPage[this.currentPage].has(row.id))
    //FOR BACKUP
    // return this.rows?.length > 0 && this.selected?.length === this.rows?.length;
  }

  updatePackCout(row: any) {
    this.openReasonModal(row)
  }

  updateRemainingAmountValue(row: any) {
    this.openReasonModal(row)
  }
  showReason(row: any) {
    this.showSketchplayBucksReason.emit(row)
  }

  showMonthCadence(row: any) {
    if (row['type'] === 'INSTALLMENT') {
      return `${row['currentLength']} of ${row['paymentLength']}`
    } else {
      return `${row['currentLength']} of ${row['paymentLength']}`
    }
  }

  showMonthCadenceLabel(rowName: string, row: any): any {
    if (row['type'] === 'DOWNPAYMENT') {
      if (row['paymentLength'] <= 1) {
        return rowName.replace(/ly$/, '');
      } else {
        return rowName.replace(/ly$/, '') + 's';
      }
    } else {
      if (row['currentLength'] <= 1) {
        return rowName.replace(/ly$/, '');
      } else {
        return rowName.replace(/ly$/, '') + 's';
      }
    }
  }

  handleSubmitUpdateDataTableValueForm() {
    if (this.updateDataTableValueForm.invalid) {
      for (const control of Object.keys(this.updateDataTableValueForm.controls)) {
        this.updateDataTableValueForm.controls[control].markAsTouched();
      }
    }
  }

  handleChangePackCount(event: any, row: any) {
    if (event && row) {
      this.selectedRow = row;
      let remainingPackCount = event?.target?.value;
      if (Number(remainingPackCount) > Number(row?.packCount)) {
        event.target.value = row?.packCount;
        showAlertMessage(`You can not add a number higher than the original package count of ${row?.packCount}`, "error");
      } else {
        let inputValue: string = remainingPackCount;
        // Remove all non-digit characters
        inputValue = remainingPackCount?.replace(/\D/g, '');
        event.target.value = inputValue;
        this.updatedPackCount = inputValue;
      }
    }
  }

  handleChangeRemainingAmount(event: any, remainingAmountVal: any) {
    if (event && remainingAmountVal) {
      // Retrieve the input value
      let inputValue = event?.target?.value;
      if (inputValue) {
        inputValue = parseFloat(inputValue?.replace(/,/g, ''));
        if (parseFloat(inputValue) > parseFloat(remainingAmountVal)) {
          event.target.value = remainingAmountVal;
          showAlertMessage(`You can not add an amount higher than the maximum remaining balance of ${formatCurrency(remainingAmountVal)}`, "error");
        } else {
          event.target.value = inputValue;
          this.updatedRemainingAmount = inputValue;
        }
      }
    }
  }

  updatePackCount(response: any) {
    let payload = {
      "id": this.selectedRow?.id,
      "packId": this.selectedRow?.packId,
      "productId": this.selectedRow?.productId,
      "newPackCount": response?.updatePackCount === '' ? this.selectedRow?.packCount : Number(response?.updatePackCount),
      "reason": response?.reason ?? ''
    }
    this.ngxSpinnerService.show();
    this.purchaseHistoryService.updatePackCount(payload).subscribe((response: any) => {
      this.ngxSpinnerService.hide();
      if (response?.status) {
        showAlertMessage(response?.message ?? "Success", "success", () => {
          this.reloadData();
        });
      } else {
        showAlertMessage(response?.message ?? "Error", "error");
      }
    }, () => {
      this.ngxSpinnerService.hide();
    });
  }

  updateRemainingAmount(response: any) {
    let payload = {
      "paymentPlanHistoryId": this.selectedRow?.paymentPlanHistoryId,
      "orgId": this.selectedRow?.organization?.id,
      "newAmount": response?.updatedRemainingAmount === '' ? parseFloat(this.selectedRow?.remainingAmountVal) : parseFloat(response?.updatedRemainingAmount),
      "reason": response?.reason ?? ''
    }
    this.ngxSpinnerService.show();
    this.purchaseHistoryService.updatePaymentPlanRemainingAmount(payload).subscribe((response: any) => {
      this.ngxSpinnerService.hide();
      if (response?.status) {
        showAlertMessage(response?.message ?? "Success", "success", () => {
          this.reloadData();
        });
      } else {
        showAlertMessage(response?.message ?? "Error", "error");
      }
    }, () => {
      this.ngxSpinnerService.hide();
    });
  }

  openReasonModal(row: any) {
    this.showUpdateCountAmountModal = true
    window.document.body.style.overflow = 'hidden'
    this.modalService.open('updateReasonPopup');
    this.selectedRow = row;
  }

  closeModal(response: any) {
    this.showUpdateCountAmountModal = false
    window.document.body.style.overflow = ''
    this.modalService.close('updateReasonPopup');
    if (response?.isSubmit) {
      if (this.tableType === this.PAYMENT_PLAN_HISTORY) {
        this.updateRemainingAmount(response);
      }
      if (this.tableType === this.PURCHASE_PACKAGE_HISTORY) {
        this.updatePackCount(response);
      }
      // showAlertMessage(response?.response?.message ?? "Success", "success", () => {
      //   this.datatableComponent.reloadData()
      // });
    }
  }

  renderBorderClass() {
    if (this.isEventPurchaseHistory) {
      return ''
    } else {
      if (this.isComingFrom === 'sidebar') {
        return 'md:flex justify-between bg-white dark:bg-[#132337] pt-1 pb-2 px-2 items-center px-10'
      } else if (this.isComingFrom === '') {
        return 'md:flex justify-between bg-white dark:bg-[#132337] pt-1 pb-4 px-2 items-center px-4 pt-4'
      } else {
        return 'md:flex justify-between bg-white dark:bg-[#132337] pt-1 pb-2 px-2 items-center'
      }
    }
  }

  onDateChange(selectedDates: any, inputName: string): void {
    const { value } = selectedDates?.target;

    if (value) {
      const [startDate, endDate] = value.split(' to ');

      // Check if we have a valid start date, and only process if the start date is new or the same as before
      if (!this.page.startDate || this.page.startDate !== startDate) {
        this.page.startDate = startDate;
        // If no end date is provided, set endDate to startDate (no reload yet)
        this.page.endDate = endDate || '';
      } else {
        // If same startDate is selected again, set the endDate to the startDate
        this.page.endDate = endDate || this.page.startDate; // Use startDate as endDate if no endDate is provided
      }

      // Only reload data if both startDate and endDate are valid
      // Also, ensure that the startDate is not equal to the endDate unless it's the same date selected twice
      if (this.page.startDate && this.page.endDate && this.page.startDate === this.page.endDate) {
        this.reloadData();
        this.startIndex = 0;
      } else if (this.page.startDate && this.page.endDate && this.page.startDate !== this.page.endDate) {
        // Only call reloadData when both dates are selected (and are different)
        this.reloadData();
        this.startIndex = 0;
      }
    } else {
      // If no value is selected, reset both startDate and endDate
      this.page.startDate = '';
      this.page.endDate = '';
    }
  }

  clearDateSelection(): void {
    this.page.startDate = '';
    this.page.endDate = '';
    const flatpickrInputElements = this.flatpickrInput.nativeElement;
    flatpickrInputElements.value = '';
    if (flatpickrInputElements._flatpickr) {
      flatpickrInputElements._flatpickr.clear();
    }
    this.reloadData();
    this.startIndex = 0;
  }

  onProfile(row: any) {
    this.router.navigate(['/member'], { queryParams: { userId: row?.id, isComingFrom: 'sketchplay-bucks' } })
  }
  onManageSkb(row: any) {
    this.manageSKB.emit(row);
  }

  renderAmountDifference(column: any, row: any): {
    amount: number,
    isCredit: boolean
  } {
    return {
      amount: column[row['amountFields']['amountValueField']],
      isCredit: column[row['amountFields']['transactionTypeField']] === 'VOLUNTEER_HOURS' || column[row['amountFields']['transactionTypeField']] === 'OTHER',
    }
  }

  checkPackExpire(expirationDate: string): boolean {
    if (expirationDate) {
      // Today's date in UTC (normalized to start of the day)
      const today = moment().utc().startOf('day');
      // Given date in UTC (normalized to start of the day)
      const givenDate = moment.utc(expirationDate).startOf('day');
      if (givenDate.isBefore(today)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  renderEventType(type: string): string {
    if (type) {
      if (type === EventType.CAMP) {
        return 'Camps';
      } else if (type === EventType.CLINIC) {
        return 'Clinic';
      } else {
        return 'Daily Training';
      }
    }
    return 'N/A';
  }
}
