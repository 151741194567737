import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-product-description',
  templateUrl: './view-product-description.component.html',
  styleUrls: ['./view-product-description.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class ViewProductDescriptionComponent implements OnInit {

  @Input() fullDescription: string = ''; // Input for full description
  @Input() isRenderHTML!: boolean;
  @Input() title!: string;
  isVisible: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  // Open the modal
  openModal(description: string) {
    this.fullDescription = description;
    this.isVisible = true;
  }

  // Close the modal
  closeModal() {
    this.isVisible = false;
  }

}
