import moment from "moment";

const WEEKDAYS: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const SMSTermsConditions = 'https://sketchplay.com/terms-and-conditions.php#section5';
const currentYear = moment().format('YYYY');
enum IPaymentPlanPaymentStatus {
  PLAN_COMPLETED = 'PLAN COMPLETED',
  ACTIVE_PLAN = 'ACTIVE PLAN',
  PLAN_CANCELED = 'PLAN CANCELED',
  INSTALLMENT_FAILED = 'INSTALLMENT FAILED'
}
enum EventType {
  CAMP = 'camp',
  CLINIC = 'clinic',
  DAILY_TRAINING = 'dailytraining',
}

export {
  currentYear, EventType, IPaymentPlanPaymentStatus, SMSTermsConditions,
  WEEKDAYS
};

