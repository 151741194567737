import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API_ADMIN_CENTER, API_EMAIL, API_PAYMENT, API_PRODUCT } from '../helpers/api_constant';
import { handleError } from '../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class PurchaseHistoryService {

  constructor(
    public http: HttpClient,
  ) { }


  getOrganizationsForPurchase(payload: { purchaseType: string }): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_PAYMENT.ORGANIZATION_PURCHASE}?purchaseType=${payload?.purchaseType}&skip=0&limit=500`).pipe(catchError(handleError))
  }
  getHouseholdMembersForPurchase(payload: { purchaseType: string }): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_PAYMENT.HOUSEHOLD_FOR_PURCHASE}?purchaseType=${payload?.purchaseType}&skip=0&limit=500`).pipe(catchError(handleError))
  }
  getPaymentPlan(paymentId: any): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_PAYMENT.GET_PAYMENT_PLAN}/${paymentId}?skip=0&limit=500`).pipe(catchError(handleError))
  }
  getUserDetailsOnDownPayPage(planId: any, userId: any): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_PAYMENT.GET_USER_DETAILS_PAY_PAGE}/${planId}/${userId}?skip=0&limit=500`).pipe(catchError(handleError))
  }
  getPaymentPlanDetails(planId: any): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_PAYMENT.GET_PAYMENT_PLAN_DETAILS}/${planId}?skip=0&limit=500`).pipe(catchError(handleError))
  }
  getPurchaseOrgList(): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_PAYMENT.GET_PURCHASE_ORG_LIST}?skip=0&limit=500`).pipe(catchError(handleError))
  }
  getAdminOrganizationsForPurchase(payload: { skip: number, limit: number, search: string }): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_PAYMENT.GET_ADMIN_ORGANIZATION_FOR_PURCHASE}?skip=${payload?.skip}&limit=${payload?.limit}&search=${payload?.search}`).pipe(catchError(handleError))
  }

  getPackageForPurchase(payload: { skip: number, limit: number, search: string }): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_PAYMENT.GET_PACKAGE_FOR_PURCHASE}?skip=${payload?.skip}&limit=${payload?.limit}&search=${payload?.search}`).pipe(catchError(handleError))
  }
  getPlanForPurchase(payload: { skip: number, limit: number, search: string }): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_PAYMENT.GET_PLAN_FOR_PURCHASE}?skip=${payload?.skip}&limit=${payload?.limit}&search=${payload?.search}`).pipe(catchError(handleError))
  }
  getFilterBySubscriptionPlan(payload: { skip: number, limit: number, search: string, orgIds: any }): Observable<any> {
    const orgIdsParam = payload?.orgIds ? `&orgIds=${payload.orgIds}` : '';

    return this.http.get<any>(
      `${environment.API}${API_PAYMENT.SUBSCRIPTION_PLAN_GET}?skip=${payload?.skip}&limit=${payload?.limit}&search=${payload?.search}${orgIdsParam}`
    ).pipe(catchError(handleError));
  }
  getEventForPurchase(payload?: { skip?: number, limit?: number, search?: string }): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_PAYMENT.GET_EVENT_FOR_PURCHASE}?skip=${payload?.skip ?? ''}&limit=${payload?.limit ?? ''}&search=${payload?.search ?? ''}`).pipe(catchError(handleError))
  }
  getEvent(payload?: { skip?: number, limit?: number, search?: string; orgIdsArrStr?: string; }): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_EMAIL.GET_EVENTS}?skip=${payload?.skip ?? ''}&limit=${payload?.limit ?? ''}&search=${payload?.search ?? ''}${payload?.orgIdsArrStr ? '&orgIds=' + payload?.orgIdsArrStr : ''}`).pipe(catchError(handleError))
  }
  getPackage(payload?: { skip?: number, limit?: number, search?: string; orgIdsArrStr?: string; }): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_EMAIL.GET_PURCHASE}?skip=${payload?.skip ?? ''}&limit=${payload?.limit ?? ''}&search=${payload?.search ?? ''}${payload?.orgIdsArrStr ? '&orgIds=' + payload?.orgIdsArrStr : ''}`).pipe(catchError(handleError))
  }
  getAllUsersForPurchase(payload: any): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_ADMIN_CENTER.GET_USER_LIST}?ordIds=${payload?.orgIds}&skip=${payload?.skip}&limit=${payload?.limit}&search=${payload?.search}&role=${payload?.role}`).pipe(catchError(handleError))
  }

  updatePackCount(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_PRODUCT.UPDATE_PACK_COUNT}`, payload).pipe(catchError(handleError))
  }

  updatePaymentPlanRemainingAmount(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_PAYMENT.UPDATE_PAYMENT_PLAN_REMAIN_AMOUNT}`, payload).pipe(catchError(handleError))
  }
}
