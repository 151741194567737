<div *ngIf="isVisible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[1006]">
  <div class="bg-white p-6 rounded-lg max-w-lg w-full dark:bg-[#1B2F48] relative">
    <i class="ri-close-line absolute top-4 right-4 text-2xl cursor-pointer" (click)="closeModal()"></i>
    <h2 class="text-xl font-bold mb-4">{{title ? title : 'Product Description'}}</h2>
    <div class="h-[12rem] overflow-y-auto p-1 content_conainer custom_scrollbar_light">
      <p *ngIf="!isRenderHTML" class="text-sm max-h-[50vh] overflow-auto">{{ fullDescription }}</p>
      <div
        *ngIf="isRenderHTML"
        [innerHTML]="fullDescription"
        class="text-black dark:text-zink-100">
      </div>
    </div>
  </div>
</div>
