import { Injectable } from '@angular/core';
import { CommonDataService } from '@app/core/services/common-data-pass.service';
import Swal from 'sweetalert2';
import { ClickControlService } from './clickControlService';

@Injectable({
  providedIn: 'root'
})
export class SwalService {

  constructor(
    private dataService: CommonDataService,
    private globalClickControlService: ClickControlService,
  ) { }

  conformationPopup = (
    text: string,
    type: 'success' | 'error' | 'warning' | 'none',
    onSaveCallback?: () => void,
    onCancelCallback?: () => void,
    titleText?: string,
    confirmButtonText: string = 'Save',
    cancelButtonText: string = 'Cancel',
    cancelButtonClass: string = ''
  ) => {
    const imageUrl = (type === 'success' ? 'assets/images/success@2x.png' : (type === 'error' ? 'assets/images/info.png' : ''));
    Swal.fire({

      //  title: titleText || '',
      title: titleText ? `
          <div style=" color: #3B3B3B; font-weight: 800; font-size: 20px; font-family: 'Poppins', sans-serif;"> ${titleText}</div>
  `: '',
      html: `
            <div style="text-align: center;">
                <div style="margin-top : 40px; color: #3B3B3B; font-weight: 800; font-size: 20px; font-family: 'Poppins', sans-serif;"> ${text}</div>
                <div style="display: flex; justify-content: center; margin: 20px 0px 0px 0px">${imageUrl !== '' ? `<img style="height: 74px; width: 74px;" src="${imageUrl}" alt=""/>` : ''}</div>
          </div>
        `,
      icon: undefined,
      showConfirmButton: true,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: `<span class="inline-block text-sm align-middle mr-1">&#10006;</span><span class="text-sm">${cancelButtonText}</span>`,
      animation: false,
      buttonsStyling: false,
      showCloseButton: false,
      allowOutsideClick: false,
      customClass: {
        popup: 'custom-popup-class-conformation',
        container: 'custom-container-class-conformation',
        actions: 'flex flex-col sm:flex-row justify-center', // Set flex layout for buttons
        confirmButton: 'text-white btn bg-custom-500 border-custom-500 hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-transparent active:ring active:ring-custom-100 w-[240px] mb-2 sm:mr-2 sm:mb-0', // Add margin and width for responsiveness
        cancelButton: cancelButtonClass ? cancelButtonClass : 'text-red-500 border-none focus:text-red-500 focus:bg-transparent focus:ring-none w-[240px] mt-[90px]',
      },
      //don't remove below line
      //didClose: onCancelCallback, 
    }).then((result) => {
      if (result.isConfirmed && onSaveCallback) {
        this.globalClickControlService.setClickDisabled(false);
        onSaveCallback();
      } else if (!result.isConfirmed && onCancelCallback) {
        this.globalClickControlService.setClickDisabled(true);
        onCancelCallback();
      }
    });
  };

  conformationRosterPopup = (
    text: string,
    type: 'success' | 'error' | 'warning' | 'none',
    onSaveCallback?: () => void,
    onCancelCallback?: () => void,
    titleText?: string,
    confirmButtonText: string = 'Save',
    cancelButtonText: string = 'Cancel',
    cancelButtonClass: string = ''
  ) => {
    const imageUrl = (type === 'success' ? 'assets/images/success@2x.png' : (type === 'error' ? 'assets/images/info.png' : ''));
    Swal.fire({

      //  title: titleText || '',
      title: titleText ? `
          <div style=" color: #3B3B3B; font-weight: 800; font-size: 20px; font-family: 'Poppins', sans-serif;"> ${titleText}</div>
  `: '',
      html: `
            <div style="text-align: center;">
                <div style=" color: #3B3B3B; font-weight: 800; font-size: 20px; font-family: 'Poppins', sans-serif;"> ${text}</div>
                <div style="display: flex; justify-content: center; margin: 20px 0px 0px 0px">${imageUrl !== '' ? `<img style="height: 74px; width: 74px;" src="${imageUrl}" alt=""/>` : ''}</div>
          </div>
        `,
      icon: undefined,
      showConfirmButton: true,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: `<span class="text-sm">${cancelButtonText}</span>`,
      animation: false,
      buttonsStyling: false,
      showCloseButton: false,
      allowOutsideClick: false,
      customClass: {
        popup: 'custom-popup-class-conformation',
        container: 'custom-container-class-conformation',
        actions: 'flex flex-col sm:flex-row justify-center', // Set flex layout for buttons
        confirmButton: 'text-white btn bg-custom-500 border-custom-500 hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-transparent active:ring active:ring-custom-100 w-[240px] mb-2 sm:mr-2 sm:mb-0', // Add margin and width for responsiveness
        cancelButton: cancelButtonClass ? cancelButtonClass : 'text-red-500 border-none focus:text-red-500 focus:bg-transparent focus:ring-none w-[240px] mt-[90px]',
      },
    }).then((result) => {
      if (result.isConfirmed && onSaveCallback) {
        this.globalClickControlService.setClickDisabled(false);
        onSaveCallback();
      } else if (!result.isConfirmed && onCancelCallback) {
        this.globalClickControlService.setClickDisabled(true);
        onCancelCallback();
      }
    });
  };
  conformationPopupForSms = (
    text: string,
    type: 'success' | 'error' | 'warning' | 'none',
    onSaveCallback?: () => void,
    onCancelCallback?: () => void,
    titleText?: string,
    confirmButtonText: string = 'Save',
    cancelButtonText: string = 'Cancel',
    cancelButtonClass: string = ''
  ) => {
    const imageUrl = (type === 'success' ? 'assets/images/success@2x.png' : (type === 'error' ? 'assets/images/info.png' : ''));
    Swal.fire({

      //  title: titleText || '',
      title: titleText ? `
          <div style=" color: #3B3B3B; font-weight: 800; font-size: 20px; font-family: 'Poppins', sans-serif;"> ${titleText}</div>
  `: '',
      html: `
            <div style="text-align: center;">
                <div style="font-size: 14px; color: #3B3B3B; font-family: 'Poppins', sans-serif;"> ${text}</div>
                <div style="display: flex; justify-content: center; margin: 20px 0px 0px 0px">${imageUrl !== '' ? `<img style="height: 74px; width: 74px;" src="${imageUrl}" alt=""/>` : ''}</div>
          </div>
        `,
      icon: undefined,
      showConfirmButton: true,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: `<span class="inline-block text-sm align-middle mr-1">&#10006;</span><span class="text-sm">${cancelButtonText}</span>`,
      animation: false,
      buttonsStyling: false,
      showCloseButton: false,
      allowOutsideClick: false,
      customClass: {
        popup: 'custom-popup-class-conformation',
        container: 'custom-container-class-conformation',
        actions: 'flex flex-col sm:flex-row justify-center', // Set flex layout for buttons
        confirmButton: 'text-white btn bg-custom-500 border-custom-500 hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-transparent active:ring active:ring-custom-100 w-[240px] mb-2 sm:mr-2 sm:mb-0', // Add margin and width for responsiveness
        cancelButton: cancelButtonClass ? cancelButtonClass : 'text-red-500 border-none focus:text-red-500 focus:bg-transparent focus:ring-none w-[240px] mt-[90px]',
      },
      //don't remove below line
      //didClose: onCancelCallback, 
    }).then((result) => {
      if (result.isConfirmed && onSaveCallback) {
        this.globalClickControlService.setClickDisabled(false);
        onSaveCallback();
      } else if (!result.isConfirmed && onCancelCallback) {
        this.globalClickControlService.setClickDisabled(true);
        onCancelCallback();
      }
    });
  };
  conformationPopupForDrawer = (
    text: string,
    type: 'success' | 'error' | 'warning' | 'none',
    onSaveCallback?: () => void,
    onCancelCallback?: () => void,
    titleText?: string,
    confirmButtonText: string = 'Save',
    middleText?: string,
    cancelButtonText: string = 'Cancel',
  ) => {
    const imageUrl = (type === 'success' ? 'assets/images/success@2x.png' : (type === 'error' ? 'assets/images/info.png' : ''));
    const cancelBtnClass = middleText ? 'text-red-500 border-none focus:text-red-500 focus:bg-transparent focus:ring-none w-[240px] mt-[30px]' : 'text-red-500 border-none focus:text-red-500 focus:bg-transparent focus:ring-none w-[240px] mt-[90px]';
    Swal.fire({

      title: titleText || '',
      html: `
            <div style="text-align: center;">
                <div style="margin-top : 40px; color: #3B3B3B; font-weight: 800; font-size: 20px; font-family: 'Poppins', sans-serif;"> ${text}</div>
                <div style="display: flex; justify-content: center; margin: 0px 0px 0px 0px">${imageUrl !== '' ? `<img style="height: 74px; width: 74px;" src="${imageUrl}" alt=""/>` : ''}</div>
          </div>
        `,
      icon: undefined,
      showConfirmButton: true,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      validationMessage: middleText,
      cancelButtonText: `<span class="inline-block text-sm align-middle mr-1">&#10006;</span><span class="text-sm">${cancelButtonText}</span>`,
      animation: false,
      buttonsStyling: false,
      showCloseButton: false,
      allowOutsideClick: false,
      denyButtonText: middleText,
      showDenyButton: true,
      customClass: {
        popup: 'custom-popup-class-conformation',
        container: 'custom-container-class-conformation',
        actions: 'flex flex-col sm:flex-row justify-center', // Set flex layout for buttons
        confirmButton: 'text-white btn bg-custom-500 border-custom-500 hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-transparent active:ring active:ring-custom-100 w-[240px] mb-2 sm:mr-2 sm:mb-0', // Add margin and width for responsiveness
        denyButton: 'mt-5 cursor-none pointer-events-none text-[#3B3B3B] text-sm font-sans w-[90%]',
        cancelButton: cancelBtnClass,
      },
      didClose: onCancelCallback,
    }).then((result) => {
      if (result.isConfirmed && onSaveCallback) {
        this.globalClickControlService.setClickDisabled(false);
        onSaveCallback();
      } else if (!result.isConfirmed && onCancelCallback) {
        this.globalClickControlService.setClickDisabled(true);
        onCancelCallback();
      }
    });
  };

}
