import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberFormatter',
})
export class NumberFormatterPipe implements PipeTransform {

    transform(value: number | string): string {
        if (value === null || value === undefined) return '';

        // Convert the value to a string
        let stringValue = value.toString();

        // Split the value into integral and decimal parts
        let [integerPart, decimalPart] = stringValue.split('.');

        // Apply Indian number formatting
        if (integerPart.length > 3) {
            let lastThree = integerPart.slice(-3);
            let otherNumbers = integerPart.slice(0, integerPart.length - 3);
            otherNumbers = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
            integerPart = otherNumbers + ',' + lastThree;
        }

        // Join the parts back together
        return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    }

}
