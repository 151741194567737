<ng-modals
  id="cropperModal"
  [placement]="'modal-center'"
  className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4">
  <div
    class="w-screen md:w-[24rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full">
    <div
      class="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500">
      <h5 class="text-16">Image Cropper</h5>
      <button
        (click)="handleCloseModelClick()"
        dismissModal
        [ModalDismiss]="'cropperModal'"
        class="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500">
        <lucide-angular name="x" [class]="'size-5'"></lucide-angular>
      </button>
    </div>
    <div>
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [containWithinAspectRatio]="true"
        [aspectRatio]="aspectRatio"
        [cropperMinWidth]="128"
        [roundCropper]="false"
        format="png"
        [alignImage]="'left'"
        (imageCropped)="imageCropped($event)"
        (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div
      class="flex items-center justify-between p-4 mt-auto border-t border-slate-200 dark:border-zink-500">
      <div class="flex justify-between gap-2 mt-5">
        <!-- <button
          type="button"
          (click)="handleCloseModelClick()"
          dismissModal
          [ModalDismiss]="'cropperModal'"
          class="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-700 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10">
          <span class="align-middle">Cancel</span>
        </button> -->
        <button
          type="submit"
          (click)="handleSaveClick()"
          [disabled]="showSubmitButton"
          [ngClass]="showSubmitButton ? 'opacity-50 cursor-default' : 'cursor-pointer'"
          class="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
          <span class="align-middle">Done</span>
        </button>
      </div>
    </div>
  </div>
</ng-modals>
