import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAddSKCredit } from '@app/shared/types/sk-bucks.type';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { handleError } from '../helpers/utils';
import { API_MEMBER, EORG_FEATURE_TYPE } from './../helpers/api_constant';

@Injectable({
  providedIn: 'root'
})

export class SKBucksService {

  constructor(
    public http: HttpClient,
  ) { }

  getUserSKBucksBalance(orgId: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_MEMBER.GET_SK_BUCKS_BALANCE}/${orgId}`).pipe(catchError(handleError))
  }

  addSKBucksCredit(payload: IAddSKCredit): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_MEMBER.ADD_SK_BUCKS_CREDIT}`, payload).pipe(catchError(handleError))
  }

  getSKBucksOrganization(): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_MEMBER.GET_SK_BUCKS_ORG}${EORG_FEATURE_TYPE.SKETCHPLAY_BUCKS}?skip=0&limit=1000`).pipe(catchError(handleError))
  }

  getAdminSKBucksBalance(userId: string | number, orgId: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_MEMBER.GET_ADMIN_SK_BUCKS_BALANCE}/${userId}/${orgId}`).pipe(catchError(handleError))
  }
}
