import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from '@app/shared/table/paging/model/page';
import { PagedData } from '@app/shared/table/paging/model/paged-data';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_ORGANIZATION } from '../helpers/api_constant';
import { DisplayBlock } from '../helpers/helper.classes';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class OrgclientService {

  constructor(
    public http: HttpClient,
    private tokenStorageService: TokenStorageService
  ) { }

  addOrgClient(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_ORGANIZATION.ORGANIZATION}`, payload);
  }

  editOrgClient(payload: any): Observable<any> {
    return this.http.put<any>(`${environment.API}${API_ORGANIZATION.ORGANIZATION}`, payload);
  }

  checkDomain(payload: any): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_ORGANIZATION.CHECK_DOMAIN}?domain=` + payload.domainName);
  }

  getOrgClient(): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_ORGANIZATION.ORGANIZATION}/all?skip=0&limit=100`);
  }

  public getResults(page: Page, displayBlock: DisplayBlock): Observable<PagedData<any>> {
    return this.http.get<any>(`${environment.API}${displayBlock?.getUrl}${displayBlock?.queryparam}skip=` + page.offset + `&limit=` + page.limit +
      `${page.search ? `&search=${page.search}` : ''}` + `${page.role ? `&role=${page.role}` : ''}` + `${page.roleIds ? `&roleIds=${page.roleIds}` : ''}` + `${page.teamIds ? `&teamIds=${page.teamIds}` : ''}` + `${page.startDate ? `&startDate=${page.startDate}` : ''}` + `${page.endDate ? `&endDate=${page.endDate}` : ''}` + `${page.org ? `&orgId=${page.org}` : ''}` + `${page.orgIds ? `&orgIds=${page.orgIds}` : ''}` + `${page.leagueIds ? `&leagueIds=${page.leagueIds}` : ''}` + `${page.eventIds ? `&eventIds=${page.eventIds}` : ''}` + `${page.type ? `&type=${page.type}` : ''}` + `${page.packageIds ? `&packageIds=${page.packageIds}` : ''}` + `${page.customListIds ? `&customListIds=${page.customListIds}` : ''}` + `${page.agegroupIds ? `&ageGroupId=${page.agegroupIds}` : ''}` + `${page.divisionId ? `&divisionId=${page.divisionId}` : ''}`).pipe(
        map(data => this.getPagedData(page, data)),
        // delay(1000 * Math.random())
      );

  }

  public getScoreList(page: Page, displayBlock: DisplayBlock, payload: any): Observable<PagedData<any>> {
    return this.http.get<any>(`${environment.API}${displayBlock?.getUrl}?orgId=${payload?.orgId}&skip=${page.offset}&limit=${page.limit}&league=${payload?.league}&division=${payload?.division}&ageGroup=${payload?.ageGroup}`).pipe(
      map(data => this.getPagedData(page, data)),
    );
  }

  private getPagedData(page: Page, data: any): PagedData<any> {
    const pagedData = new PagedData<any>();
    page.totalElements = data?.data?.totalDocs;
    page.totalPages = page.totalElements / page.size;
    page.pageNumber = data?.data?.page;
    page.limit = data?.data?.limit;
    const start = page.pageNumber * page.size;
    const end = Math.min(start + page.size, page.totalElements);
    pagedData.data.push(data?.data?.docs);
    pagedData.page = page;
    return pagedData;
  }

  getClientDetails(payload: { id: number | string }): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_ORGANIZATION.ORGANIZATION}/${payload?.id}`);
  }
}
