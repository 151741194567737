import { NgModule } from '@angular/core';
import { CurrentTimezonePipe } from './current-timezone.pipe';
import { DaysAgoPipe } from './days-ago.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { FormatCurrencyPipe } from './format-currency.pipe';
import { NumberFormatterPipe } from './number-format.pipe';
import { OrdinalDatePipe } from './ordinal.pipe';
import { PhoneNumberPipe } from './phone-no-format.pipe';

@NgModule({
  declarations: [
    CurrentTimezonePipe,
    DaysAgoPipe,
    PhoneNumberPipe,
    FormatCurrencyPipe,
    EllipsisPipe,
    OrdinalDatePipe,
    NumberFormatterPipe
  ],
  imports: [],
  exports: [
    CurrentTimezonePipe,
    DaysAgoPipe,
    PhoneNumberPipe,
    FormatCurrencyPipe,
    EllipsisPipe,
    OrdinalDatePipe,
    NumberFormatterPipe
  ],
})

export class PipesModule { }
