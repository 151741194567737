<ng-modals
  id="updateReasonPopup"
  [placement]="'modal-center'"
  className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4 bg-[rgba(0,0,0,0.4)] w-full h-full justify-center items-center">
  <div
    class="w-[calc(100vw-3rem)] sm:w-[36rem] bg-white shadow rounded-md dark:bg-[#0D1C2F] flex flex-col">
    <div class="py-6 max-h-[calc(theme('height.screen')_-_80px)] overflow-y-auto no-scrollbar">
      <form [formGroup]="updateNoteForm" (ngSubmit)="handleSubmit()">
        <div>
          <h6 class=" dark:text-[#C9D7E9] text-2xl px-6 font-kanit font-extrabold italic">
            {{tableType === PURCHASE_PACKAGE_HISTORY ? 'Update Remaining Package Count' : 'Update Discount'}}
          </h6>
          <p *ngIf="tableType === PAYMENT_PLAN_HISTORY" class="px-6 text-sm mt-1">
            Enter the amount you would like to discount this payment plan below.
          </p>
          <div class="w-full my-4">
            <hr class="dark:border-[#1D334E] border-[#e2e8f0] border-[1.5px]">
          </div>
          <div class="mt-4 px-6">
            <!-- update package count -->
            <div *ngIf="tableType === PURCHASE_PACKAGE_HISTORY" class="grid grid-cols-12 gap-5">
              <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6">
                <label for="teamName" class="block mb-2 text-base font-normal">Package Count:</label>
                <input
                  *ngIf="this.selectedRow?.packCount !== -1"
                  type="text"
                  placeholder="Package Count"
                  [disabled]="true"
                  [readOnly]="true"
                  [value]="this.selectedRow?.packCount"
                  class="py-3 pl-3 w-full border border-slate-200 rounded-md dark:bg-[#1C2F45] dark:border-[#284365] focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:focus:border-custom-800 ng-pristine ng-valid ng-touched opacity-60"
                />
                <i *ngIf="this.selectedRow?.packCount === -1" class="ri-infinity-line text-3xl"></i>
              </div>
              <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6">
                <label for="teamName" class="inline-block mb-2 text-base font-normal">Remaining Package Count: <span class="text-red-500">*</span></label>
                <input
                  type="text"
                  formControlName="packCount"
                  ng-mask="9999999999" 
                  restrict="reject" 
                  ng-pattern="/^\d*$/"
                  placeholder="Update package count *"
                  (input)="handleChangePackCount($event)"
                  class="py-3 pl-3 w-full border border-slate-200 rounded-md dark:bg-[#1C2F45] dark:border-[#284365] focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:focus:border-custom-800 ng-pristine ng-valid ng-touched"
                />
                <div
                  *ngIf="packCount.invalid && isFormSubmitted"
                  class="invalid-feedback">
                  <div
                    *ngIf="packCount.errors?.['required']"
                    class="text-red-500 ml-1 text-[12px] mt-1">
                    This field is required.
                  </div>
                </div>
                <div
                  *ngIf="packCount.invalid && isFormSubmitted"
                  class="invalid-feedback">
                  <div
                    *ngIf="packCount.errors?.['min']"
                    class="text-red-500 ml-1 text-[12px] mt-1">
                    Invalid value.
                  </div>
                </div>
              </div>
            </div>
            <!-- update payment plan remaining amount -->
            <div *ngIf="tableType === PAYMENT_PLAN_HISTORY" class="grid grid-cols-12 gap-5">
              <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6">
                <label for="teamName" class="inline-block mb-2 text-base font-normal">Remaining Amount:</label>
                <div class="flex">
                  <span class="inline-block py-[13px] px-[20px] border ltr:border-r-0 rtl:border-l-0 border-slate-200 dark:border-zink-500 ltr:rounded-l-md rtl:rounded-r-md bg-[#e5e7eb] dark:bg-black opacity-60">
                    $
                  </span>
                  <input
                    type="text"
                    placeholder="Remaining mount"
                    [disabled]="true"
                    [readOnly]="true"
                    [value]="this.selectedRow?.remainingAmount"
                    class="py-3 pl-3 w-full ltr:rounded-l-none rtl:rounded-r-none border border-slate-200 rounded-md dark:bg-[#1C2F45] dark:border-[#284365] focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:focus:border-custom-800 ng-pristine ng-valid ng-touched opacity-60"
                  />
                </div>
              </div>
              <div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6">
                <label for="teamName" class="inline-block mb-2 text-base font-normal">Discount Amount: <span class="text-red-500">*</span></label>
                <div class="flex h-[50px]">
                  <span class="inline-block py-[13px] px-[20px] border ltr:border-r-0 rtl:border-l-0 border-slate-200 dark:border-zink-500 ltr:rounded-l-md rtl:rounded-r-md bg-[#e5e7eb] dark:bg-black">
                    $
                  </span>
                  <input
                    type="text"
                    formControlName="paymentPlanDiscountAmount"
                    step="0.01"
                    mask="separator.2"
                    thousandSeparator=","
                    pattern="\d+(\.\d{1,2})?"
                    placeholder="Enter discount amount *"
                    (input)="handleChangeRemainingAmount($event)"
                    class="py-3 pl-3 w-full ltr:rounded-l-none rtl:rounded-r-none border border-slate-200 rounded-md dark:bg-[#1C2F45] dark:border-[#284365] focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:focus:border-custom-800 ng-pristine ng-valid ng-touched"
                  />
                </div>
                <div
                  *ngIf="paymentPlanDiscountAmount.invalid && isFormSubmitted"
                  class="invalid-feedback">
                  <div
                    *ngIf="paymentPlanDiscountAmount.errors?.['required']"
                    class="text-red-500 ml-1 text-[12px] mt-1">
                    This field is required.
                  </div>
                </div>
                <div
                  *ngIf="paymentPlanDiscountAmount.invalid && isFormSubmitted"
                  class="invalid-feedback">
                  <div
                    *ngIf="paymentPlanDiscountAmount.errors?.['min']"
                    class="text-red-500 ml-1 text-[12px] mt-1">
                    Invalid value.
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <textarea
                type="text"
                id="note"
                name="note"
                formControlName="note"
                placeholder="You must enter a reason."
                required
                rows="12"
                class="py-[15px] text-[14px] form-input border-slate-200 dark:border-zink-500 bg-transparent focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"></textarea>
              <div
                *ngIf="note.invalid && isFormSubmitted"
                class="invalid-feedback">
                <div
                  *ngIf="note.errors?.['required']"
                  class="text-red-500 ml-1 text-[12px] mt-1">
                  This field is required.
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end my-6 px-6">
            <div class="text-center">
              <button
                class="w-[130px] mr-4 text-[#3B82F6] transition-all duration-200 ease-linear btn bg-transparent border-[#3B82F6]"
                (click)="handleCloseModal(false)">
                Cancel
              </button>
            </div>
            <button
              type="submit"
              class="text-[#C9D8E9] w-[130px] font-normal btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-modals>